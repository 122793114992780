<template>
  <div class="product-page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header d-flex py-3 align-center">
        <span class="page-title">{{ $t('Apps') }}</span>
        <v-spacer></v-spacer>
      </v-col>
      <v-col v-for="item in apps" :key="item.id" cols="12" class="mt-3 d-flex elevation-2 form-component align-center">
        <div class="ml-3 d-flex  align-center pointer" @click="gotoApp(item.route)">
          <v-avatar tile class="mr-2">
            <img :src="item.image" alt="" srcset="" />
          </v-avatar>
          <div class="ml-4 py-2">
            <h3>
              {{ item.name }}
            </h3>
            <div>
              {{ item.desc }}
            </div>
            <div>
              <v-chip v-for="att in item.attr" :key="att" class="mr-1" small>
                {{ att }}
              </v-chip>
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" class="py-10">
        Working with a developer on your shop?
        <span class="private-app" @click="gotoPrivateApp">Manage private apps.</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apps: [
        {
          id: 1,
          name: 'Boost Upsell',
          route: 'website.apps.upsell.list',
          desc: 'Increase Order value & Sales with Smart Upselling',
          image: 'https://minio.lattehub.com/lattehub/static/20220217/1.png',
          attr: ['Free', 'Most Popular'],
        },
        {
          id: 2,
          name: 'Product Review',
          route: 'website.apps.product-review.list',
          desc: '',
          image: 'https://minio.lattehub.com/lattehub/static/20220217/2.png',
          attr: ['Free'],
        },
        {
          id: 3,
          name: 'Ali Dropship Connector',
          route: 'website.apps.ali-connector.import',
          desc: '',
          image: 'https://minio.lattehub.com/lattehub/static/20220217/3.png',
          attr: ['Free'],
        },
        // {
        //   id: 4,
        //   name: 'Print On Demand Products',
        //   route: 'website.apps.print-hub.catalog',
        //   desc: 'Make Print-On-Demand easier and more profitable than ever',
        //   image: 'https://minio.lattehub.com/lattehub/static/20220217/4.png',
        //   attr: ['Free'],
        // },
        {
          id: 5,
          name: 'Migrate To Lattehub',
          route: 'website.apps-migrate',
          desc: '',
          image: 'https://minio.lattehub.com/lattehub/static/20220217/4.png',
          attr: ['Free'],
        },
      ],
      fakeImage:
        'https://minio.lattehub.com/img/200/200/resize/610e701311273e0009f1bd32/2021/10/06/lattehub-image-615d6e7cba78eab2e9bc35ed.png',
    };
  },
  methods: {
    gotoApp(name) {
      this.$router.push({
        name: name,
      });
      this.$store.commit('setNavigationMini', true);
    },
    gotoPrivateApp() {
      this.$router.push({ name: 'website.apps-private' });
      this.$store.commit('setNavigationMini', false);
    },
  },
};
</script>
<style lang="scss">
.private-app {
  color: #0091eb;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
